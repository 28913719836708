import React from "react";
import { Link } from "react-router-dom";

import configData from "../../config/configData";

import "./postCard.css";

const ProductCard = ({ post }) => {
  const { id, name, code, unitPrice, imageUrl, category} = post;
  return (
    <div className="okiro__product-card" >
      <div className="okiro__product-card__image">
        <Link to={{ pathname: `/product/${id}` }}>
          { imageUrl ? (
             <img src={imageUrl} alt="post-card" />
          ) 
          : <img src='https://cloud-s5.fyslink.com/api-catalog-bk/api/v1/public/files/temp/afec417c-a96a-45eb-8533-d66d7c287273.webp' alt="post-card" />

          }
         
        </Link>
      </div>
      <div className="okiro__product-card__content">
        <Link to={{ pathname: `/product/${id}` }}>
          <h3><div><i class="fa fa-star start-sty"></i> {name}</div> </h3>
        </Link>
        <Link to={`/`}>
          <div class="name-category">
           <p >{category.name}</p>
          </div>
        
        </Link>
      </div>
      <div class="product-bottom-details">
				<div class="product-price">{configData.currencyFormat} {unitPrice}</div>
				<div class="product-links">
					{/* <a href="" class="ml-2"><i class="fa fa-heart"></i></a> */}
					<a href=""><i class="fa fa-shopping-cart"></i></a>
				</div>
			</div>
    </div>
  );
};

export default ProductCard;
