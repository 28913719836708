import { useContext } from "react";
import { ToastsContext } from "../context/ToastsContext";

export default function useToasts() {
    const {
        toasts,
        toastsCount,
        createToastAction,
        removeToastAction,
    } = useContext(ToastsContext);

    const addToast = ({title = "Titulo", type = "", message = "Este es un toast"}) => {
        createToastAction({ title, type, message })
        setTimeout(() => {
            removeToastAction(toastsCount)
        }, 3000);
    };

    const removeToastById = (id) => {
        removeToastAction(id)
    };

    return {
        addToast,
        toasts,
        removeToastById,
    };
}
