import Request from './Request';
//import UserModelsEnum from '../helpers/enums/UserModelsEnum'
import HttpResponseEmun from '../helpers/enums/HttpResponseEnum';

export const verifyEmail = async (email) => {
  try {
    const response = await Request.get('/api/v1/public/users/by/username/', { email })
    if (response.status === HttpResponseEmun.OK) {
      throw Error(`El correo electrónico ${email} ya está registrado`)
    }
    return true
  } catch (error) {
    console.error(error)
  }
  return false
}

export const login = async (username, password) => {
  try {
    const response = await Request.post('/api/v1/public/auth', {
      username,
      password,
    }, {},'PUBLIC')
   
    if (response.status === HttpResponseEmun.OK && response.data.id) {
      const { id, name, lastNames, username, token, permissions, roles, type, photo, user, model } = response.data 
      localStorage.setItem('authorization', token)


        localStorage.setItem('user', JSON.stringify({ id, name, lastNames, username, token, permissions, roles, type, photo }))
      
      // if(user.theme){
      //   localStorage.setItem('theme',JSON.stringify(user.theme.theme))  
      // }else{
      //   localStorage.setItem('theme',JSON.stringify(
      //     {
      //       'background-color':"#272a30",
      //       'color':"#fafafa ",
      //     }
      //   ))
       
      // }
      return { id, name, lastNames, username, token, permissions, roles, type, photo, user, model }
    }
  } catch (error) {
    console.error(error)
  }
  return false
}
