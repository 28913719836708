import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Footer from "./container/footer/Footer";
import Header from "./container/header/Header";
import HomePage from "./container/pages/homepage/HomePage";
import MembershipPage from "./container/pages/membership/MembershipPage";
import SubscribePage from "./container/pages/subscribe/SubscribePage";
import ContactPage from "./container/pages/contact/ContactPage";
import SignUpPage from "./container/pages/signup/SignUpPage";
import SignInPage from "./container/pages/signin/SignInPage";
import BlogPage from "./container/pages/blog/BlogPage";
import AuthorPage from "./container/pages/author/AuthorPage";
import AuthorDetails from "./container/pages/authorDetails/AuthorDetails";
import TagPage from "./container/pages/tags/TagPage";
import ProductsPage from "./container/pages/products/productsPage";
import TagDetails from "./container/pages/tagDetails/TagDetails";
//import ProductDetails from "./container/pages/productDetails/productDetails";
import NotFound from "./container/notfound/NotFound";
import Features from "./container/pages/features/Features";
import AuthProvider from './context/AuthContext';
import { ThemeProvider } from './context/ThemeContext';
import { ToastsProvider } from './context/ToastsContext';

import "./App.css";
import AboutUs from "./container/pages/about/AboutUs";
import { ProductsProvider } from "./context/ProductsContext";
import ProductPage from "./container/pages/product/ProductPage";

function App() {
  return (
    // <Router>
      <div className="main-p">
        <AnimatePresence>
        <ThemeProvider>
        <ProductsProvider>
          <AuthProvider>
          <ToastsProvider>
          
          <Router  basename={process.env.REACT_APP_CONTEXT || '/'}>
            <Switch>

              <Route exact path="/subscribe" component={SubscribePage} />
              <Route exact path="/contact" component={ContactPage} />
              <Route exact path="/signup" component={SignUpPage} />
              <Route exact path="/signin" component={SignInPage} />
              <Redirect exact path='/' to='/signin' />
              
              <Route  basename={process.env.REACT_APP_CONTEXT || '/'}>
                <Header />
                <Switch>
                  <Route path="/home" component={HomePage} />
                  <Route exact path="/authors" component={AuthorPage} />
                  <Route
                    exact
                    path="/author/:authorName"
                    component={AuthorDetails}
                  />
                  {/* <Route exact path="/membership" component={MembershipPage} />
                  <Route exact path="/features" component={Features} />
                  <Route exact path="/about" component={AboutUs} />
                  <Route exact path="/article/:id" component={BlogPage} />
                  <Route exact path="/tags" component={TagPage} />
                  <Route exact path="/tag/:tagName" component={TagDetails} /> */}
                  <Route exact path="/product/:id" component={ProductPage} />
                  <Route exact path="/products" component={ProductsPage} />
                  <Route exact path="/products/:categoryName" component={ProductsPage} />
                  
                  <Route path="*" component={NotFound} />
                </Switch>
                <Footer />
              </Route>
            </Switch>
            </Router>
            </ToastsProvider>
            </AuthProvider>
            </ProductsProvider>
          </ThemeProvider>
        </AnimatePresence>
      </div>
    // </Router>
  );
}

export default App;
