import Axios from 'axios'
import HttpResponseEmun from '../helpers/enums/HttpResponseEnum'

const Request = {
  get: async (url, params = {}, options = {}, type = 'PRIVATE') => {
    let headers =
      localStorage.getItem('authorization') &&
      localStorage.getItem('authorization') !== 'null'
        ? { authorization: 'Bearer ' +localStorage.getItem('authorization') }
        : {}
    
    if( type === 'PUBLIC'){
       headers = { 'x-token': `${process.env.API_TOKEN_PUBLIC}` };
    }
      
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
      headers,
      params,
      validateStatus: function (status) {
        return status < HttpResponseEmun.InternalServerError // Resolve only if the status code is less than 500
      },
      ...options,
    })
    return response
  },
  post: async (url, payload, options = {}, type = 'PRIVATE') => {
    let headers = {};

    headers =  localStorage.getItem('authorization') &&
      localStorage.getItem('authorization') !== 'null'
        ? { authorization: localStorage.getItem('authorization') }
        : {}

     if( type === 'PUBLIC'){
         headers = { 'x-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqaXJrYS1wdWJsaWMiLCJuYW1lIjoiQXV0aG9yaXphdGlvbiIsImlhdCI6MTUxNjIzOTAyMn0.pDU-3E-XTKHvtboiUZJ4qE5YLo9w5eZbTOn6-YXjJcI" };
     } 

    const response = await Axios.post(
      `${process.env.REACT_APP_API_URL}${url}`,
      payload,
      {
        headers,
        validateStatus: function (status) {
          return status < HttpResponseEmun.InternalServerError // Resolve only if the status code is less than 500
        },
        ...options,
      }
    )
    return response
  },
  put: async (url, payload, options = {}) => {
    const headers =
      localStorage.getItem('authorization') &&
      localStorage.getItem('authorization') !== 'null'
        ? { authorization: localStorage.getItem('authorization') }
        : {}
    const response = await Axios.put(
      `${process.env.REACT_APP_API_URL}${url}`,
      payload,
      {
        headers,
        validateStatus: function (status) {
          return status < HttpResponseEmun.InternalServerError // Resolve only if the status code is less than 500
        },
      }
    )
    return response
  },
  delete: async (url, params = {}, options = {}) => {
    const headers =
      localStorage.getItem('authorization') &&
      localStorage.getItem('authorization') !== 'null'
        ? { authorization: localStorage.getItem('authorization') }
        : {}
    const response = await Axios.delete(
      `${process.env.REACT_APP_API_URL}${url}`,
      {
        headers,
        params,
        validateStatus: function (status) {
          return status < HttpResponseEmun.InternalServerError // Resolve only if the status code is less than 500
        },
        ...options,
      }
    )
    return response
  },
}

export default Request;
