import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
//import ArticleCard from "../../../components/article/ArticleCard";
import CategoriesCard from "../../../components/articleCategories/CategoriesCard";
import CustomButton from "../../../components/button/CustomButton";
//import LatestPost from "../../../components/latestpost/LatestPost";
//import PreviewPosts from "../../../components/preview/PreviewPosts";
//import Subscribe from "../../../components/subscribe/Subscribe";
import postData from "../../../data/post/post";
import { pageVariants } from "../../../animation/motion";
import useFetchCategories from "../../../hooks/useFetchCategories";

import "./homePage.css";
//import PreviewPosts from "../../../components/preview/PreviewPosts";

const HomePage = () => {
  const [numberofArticle, showNumberOfArticle] = useState(11);

  //const [ setCategories] = useState([]);

  const {response} = useFetchCategories();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    showNumberOfArticle((prev) => prev + 4);
  };
  let data = [];
  data = response.content;

  console.log(data);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="okiro__homepage"
    >



      <div className="okiro__homepage__aritclesDiv">
        <div className="okiro__homepage__show-articles">
          
           { data ? 
              data.slice(0, 4).map((item) => (
                <CategoriesCard data={item} key={item.name} />
              ))

              : null
              
            }

          
        </div>

        {numberofArticle > postData.length ? null : (
          <CustomButton title="Load more" click={handleClick} />
        )}
      </div>

    </motion.div>
  );
};

export default HomePage;
