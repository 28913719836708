import React,{ createContext,useEffect,useState } from "react";
import { getAllProducts } from "../services/Products";

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {  
  const [filter, setFilter] = useState({stateParam: 'ACTIVE', showRows: 20})
  const [products, setProducts] = useState([]);
  const [productsCont, setProductsCont] = useState([]);





  const getProducts = async () => {


    try {

       const res = await getAllProducts({...filter});
       setProducts(res.data);
       setProductsCont(res.data.content);
       
    } catch (error) {
      console.error(error);
    } finally{
     // setLoading(false);
    }

  }

useEffect(() => {
  getProducts()
}, [filter])



  const contextValue = {
    filter,
    setFilter,
    products,
    productsCont
  }
 

  return(
    <ProductsContext.Provider value={contextValue}>
      {children}
    </ProductsContext.Provider>
  )
  
}