import { useEffect, useState } from "react";
//import UserModelsEnum, { FaqUserTypes } from "../helpers/enums/UserModelsEnum";
//import { useAuth } from '../context/AuthContext';
import { getProductId } from "../services/Products";


function useFetchProduct(id) {


   // const [filter, setFilter] = useState({stateParam: 'ACTIVE', showRows: 20});
    const [lastPage, setLastPage] = useState(false);
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(true);

   //const [chagedCategorieLinks, setChagedCategorieLinks] = useState(true);

   // const {user} = useAuth();

    const getFetchProductId = async () => {

        setLoading(true);

        //let type;
    
        // if(user.model === UserModelsEnum.Broker || user.model === UserModelsEnum.Developer) {
        //   type = 'SUDO';
        // }
    
        try {

           const res = await getProductId(id);

          setResponse(res.data);
          if(res.data.empty || res.data.last) setLastPage(true);
        } catch (error) {
          console.error(error);
        } finally{
          setLoading(false);
        }
    
    }



    useEffect(() => {

       getFetchProductId();
    
    }, []);

    return {loading, response, lastPage};


}

export default useFetchProduct