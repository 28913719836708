import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
//import { BsThreeDots } from "react-icons/bs";
import { FiSearch, FiUser } from "react-icons/fi";
//import  logo  from "../../assets/images/logo192.png";
//import okiro from "../../assets/okiro.svg";
import  logo  from "../../assets/images/logoM.png";
import CustomButton from "../../components/button/CustomButton";

import useFetchCategories from "../../hooks/useFetchCategories";

import { ProductsContext } from "../../context/ProductsContext";

import "./header.css";


const Header = () => {
  const [showDotLinks, setShowDotLinks] = useState(false);
  const [showMenuLinks, setShowMenuLinks] = useState(false);
  const [chagedCategorieLinks, setChagedCategorieLinks] = useState(false);
  //const [filter, setFilter] = useState();

  const { filter, setFilter } = useContext(ProductsContext);

  const {response} = useFetchCategories();

  console.log('iniciando Header');

  const linksCategory = false; 
  
  
  
  
  let data = [];
  data = response.content;

  const handleClick2 = (cname) =>{
    setFilter({stateParam: 'ACTIVE', showRows: 20,categoriesParam: cname})
   //setChagedCategorieLinks(cname);
    console.log('Changed Categories: ',cname);
  };

  const handleClickSearch = (e) =>{
    const text = e.target.value;
    setFilter({...filter, searchParam: text})
   //setChagedCategorieLinks(cname);
    console.log('Search: ', text);
  };




  return (
    <div className="okiro__header">

      {linksCategory ?(
        <div className="okiro__header__links">
          <ul>
          
            {/* <li className="special-link">
              <NavLink
                exact
                to="/products"
                activeClassName="isActive"
              >
                Categoría
              </NavLink>
            </li> */}

            { data ? 
                      data.map((item) => (
                      <li className="special-link">
                        <NavLink
                          exact
                          to={{ pathname: `/products/${item.pathName}` }}
                          activeClassName="isActive"
                          onClick={() => handleClick2(item.pathName)}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                      ))

                      : null
                      
                    }
            {/* <li>
              <BsThreeDots onClick={() => setShowDotLinks(!showDotLinks)} />

              {showDotLinks ? (
                <div className="okiro__header__dotLinks">
                  <ul>
                    { data ? 
                      data.map((item) => (
                      <li className="special-link">
                        <NavLink
                          exact
                          to={{ pathname: `/products/${item.name}` }}
                          onClick={handleClick2(item.name)}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                      ))

                      : null
                      
                    }
                  </ul>

                
                </div>
              ) : null}
            </li> */}
          </ul>
        </div>
        ) : null
      }

      <div className="okiro__header__links_search">
     
          
             <FiSearch />
            <div className="okiro__subscribe-input ">
            
            <input placeholder="¿Que estás buscando?" type="text" onChange={handleClickSearch} />
          </div>
        
      

      </div>

      <div className="okiro__header__logo">

          <img src={logo} alt="Catalog" />

      </div>
      <div className="okiro__header__sign">
        <ul>
          {/* <li>
            <FiSearch />
          </li> */}
          <li className="special-link ">
            <NavLink
              exact
              to="/signin"
              activeClassName="isActive"
              onClick={() => setShowDotLinks(false)}
            >
              Ingresar  <FiUser />
            </NavLink>

           
          </li>
        </ul>
      </div>

      <div className="okiro__header__hamburger">
        <div
          onClick={() => setShowMenuLinks(!showMenuLinks)}
          className={`hamburger hamburger--collapse  ${
            showMenuLinks ? "is-active" : ""
          }  `}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>

        {showMenuLinks ? (
          <div className="okiro__header__hamburger--linksDiv">
            <ul>
              <li className=" special-link">
                <NavLink
                  exact
                  to="/signin"
                  activeClassName="isActive"
                  onClick={() => setShowMenuLinks(!showMenuLinks)}
                >
                  Ingresar
                </NavLink>
              </li>
            </ul>
            <div
              className="okiro__header__hamburger--linkDiv-search"
              onClick={() => setShowMenuLinks(!showMenuLinks)}
            >
              search <FiSearch />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
