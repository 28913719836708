import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ProductsContext } from "../../context/ProductsContext";
import "./menu.css";

import useFetchCategories from "../../hooks/useFetchCategories";


const Menu = () => {

    const { setFilter } = useContext(ProductsContext);
    const {response} = useFetchCategories();
  
    let data = [];
    data = response.content;

    const handleClick = (cname) =>{
        setFilter({stateParam: 'ACTIVE', showRows: 20,categoriesParam: cname})
        console.log('Changed Categories: ',cname);
    };
  


    
    return (
    <div class="catalog__homepage__menu">
        <div class="catalog__menu">
            <div class="catalog__menu__links">
                <h3 class="side-menu_section-title__2NtpL">Categorías</h3>
                <ul class="side-menu_section-list__2aMqd">
                    <li className="special-link">
                        <a href="/products">
                            {/* <i class="fa fa-check m-">
                                </i> */}
                                Todo</a>
                    </li>

                    { data ? 
                    data.map((item) => (
                    <li className="special-link">
                      <NavLink
                         exact
                         to={{ pathname: `/products/${item.pathName}` }}
                         activeClassName="isActive"
                        onClick={() => handleClick(item.pathName)}
                      >
                        {item.name}
                      </NavLink>
                    </li>
                    ))

                    : null
                    
                  }

                </ul>
            </div>
        </div>
    </div>
    );

};


export default Menu;