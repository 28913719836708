import  logo  from "../assets/images/logoM.png";


const configData =
    {
      logo: {logo},
      currencyFormat: "USD",
      decimal: 2
    };
  

export default configData;
  