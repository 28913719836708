import React from "react";
//import PostCard from "../postcard/PostCard";
import ProductCard from "../product-card/ProductCard";

import "./previewPosts.css";

const PreviewProducts = ({ postData, title }) => {
  return (
    <div className="okiro__homepage__previewPosts">

      <div className="okiro__homepage__previewPosts-posts">
      { postData ? 
        postData.map((post) => (
          <ProductCard post={post} key={post.code} />
        ))
        : null
      }
      </div>
    </div>
  );
};

export default PreviewProducts;
