import React from "react";
import Tag from "../tag/Tag";
import { IoLockClosed } from "react-icons/io5";

import "./articleCard.css";
import { Link } from "react-router-dom";

const CategoriesCard = ({ data }) => {
  console.log('Categories: ', data);
  const { imageUrl, name, color, description, member } = data;
  
  return (
    <div className="okiro__article-card" style={{ background: color }}>
      <div className="okiro__article-card_img">
        <Link to='/'>
          <img src={imageUrl} alt=''/>
        </Link>
      </div>
      <div className="okiro__article-card_content">
        <div className="okiro__article-card_content-name">
          <Link to='/products'>
            <h3>{description}</h3>
          </Link>
        </div>
        <div className="okiro__article-card_content-tag">
          <Tag title={name} link='/products' />
          {member ? (
            <div className="okiro__article-card_content-tag_private">
              <IoLockClosed />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CategoriesCard;
