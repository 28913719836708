import React from 'react'
import useToasts from "../../hooks/useToasts";
import Toast from "./Toast";
import "./index.css";

export default function ToastContainer() {
    const { toasts, removeToastById } = useToasts();
    return (
        <div className='toast-container'>
            {toasts.map((toast) => (
                <Toast
                    key={toast.id}
                    {...toast}
                    onClose={() => {
                        removeToastById(toast.id);
                    }}
                />
            ))}
        </div>
    );
}
