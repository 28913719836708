import HttpResponseEmun from '../helpers/enums/HttpResponseEnum';
import Request from '../services/Request';


export const verifyCode = async (code) => {
    try {
        const res = await Request.get("/products/by-code/"+code);

        if(res.status === HttpResponseEmun.NotFound){
            return {message: `El código "${code}" está disponible `, unique: true};
        }
        if(res.status === HttpResponseEmun.OK){
            return {message: "Este código ya existe", unique: false};
        }
    } catch (error) {
        return {message: "Error al validar el código", unique: false};
    }
}

export const getProductId = async (id) => {
    try {
        const res = await Request.get(`/api/v1/products/${id}`);
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}

export const getAllProducts = async (filter) => {
    try {
        const res = await Request.get("/api/v1/products", filter);
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}

